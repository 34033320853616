import React, { Fragment } from 'react'
import Facts from '../../components/Facts'
import Team from '../../components/Team'
import Testimonial from '../../components/Testimonial'
import { Four, One, Row, RowGroup, Three } from '../../components/Grid'
import OtherProjects from '../../components/OtherProjects'
import ProjectsContext from '../../components/ProjectsContext'
import Image from '../../components/Image'
import Caption from '../../components/Caption'
import SEO from '../../components/SEO'
import { ProjectName } from '../../components/Style'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
      "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  },{
    "@type": "ListItem",
    "position": 2,
    "item": {
      "@id": "https://500tech.com/projects/thetaray",
      "name": "Thetaray"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <ProjectsContext.Consumer clientName="ThetaRay">
        {({ allProjects, currentProject }) => (
          <Fragment>
            <SEO
              path="/projects/thetaray"
              title="Featured AngularJS project for ThetaRay by 500Tech"
              description="We’ve built an AngularJS application in the security industry for ThetaRay."
              ld={breadcrumbs}
            />

            <ProjectName>{currentProject.projectName}</ProjectName>
            <Facts {...currentProject} />

            <RowGroup>
              <Row>
                <Three>
                  <p>
                    ThetaRay takes structured data and applies its algorithms to
                    figure out anomalies in the data.
                  </p>

                  <p>
                    We worked closely with Thetaray to build a rich dashboard on
                    top of their anomaly detection platform. The UI included
                    huge data grids that ran smoothly, and interactive
                    tailor-made visualizations.
                  </p>
                </Three>
              </Row>

              {/*src: https://www.scmagazine.com/thetaray-advanced-analytics-platform/article/530356/*/}

              <Row>
                <Four>
                  <Image path="projects/thetaray/thetaray-1.jpg" />
                </Four>
              </Row>
            </RowGroup>

            <Team team={currentProject.team} />

            <Row>
              <One>
                The project includes very nice dashboards that show a lot of
                data (could be millions of rows, thousands of columns).
              </One>
              <Three>
                <Image path="projects/thetaray/thetaray-2.jpg" />
                <Caption>
                  Complex work with SVG, visualisations, math calculations, D3.
                  Very complex pages, many components that interact with each
                  other. Complex client side logic like filtering, caching with
                  local storage, etc.
                </Caption>
              </Three>
            </Row>

            <Testimonial />

            <OtherProjects currentProject={currentProject} />
            <ContactForm />
          </Fragment>
        )}
      </ProjectsContext.Consumer>
    </Layout>
  )
}
